<template>
  <div class="ps-5 pe-5">
    <div class="row">
      <div class="col-12">
        <div class="dashboard-reviews">

          <div class="h-auto">

            <div class="row h-100 align-items-center pt-3 m-0 pb-4">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
                <div class="card shadow sm">
                  <div class="card-body p-0" id="reviewsList">

                    <div class="row m-0 p-0">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                        <ul class="nav nav-tabs justify-content-center">
                          <li class="nav-item">
                            <a href="#hostReviews" id="hostReviewsTabUrl" class="nav-link active" data-bs-toggle="tab">Host Reviews</a>
                          </li>
                          <li class="nav-item">
                            <a href="#guestReviews" id="guestReviewsTabUrl" class="nav-link" data-bs-toggle="tab">Guest Reviews</a>
                          </li>
                          <li class="nav-item">
                            <a href="#serviceReviews" id="serviceReviewsTabUrl" class="nav-link" data-bs-toggle="tab">Service Reviews</a>
                          </li>
                          <li class="nav-item">
                            <a href="#neighbourhoodReviews" id="neighbourhoodReviewsTabUrl" class="nav-link" data-bs-toggle="tab">Neighbourhood Reviews</a>
                          </li>
                        </ul>

                        <div class="tab-content">

                          <!-- Loading -->
                          <div v-if="loading" class="scroll-width-thin">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                <Loader :loading="loading" :small="false"></Loader>
                              </div>
                            </div>
                          </div>

                          <!-- Host Reviews Tab -->
                          <div v-if="!loading" class="tab-pane show active fade scroll-width-thin" id="hostReviews">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                <ReviewsHost :reviewsFeed="hostReviews" :hideRating="true" :showAdditionalInfo="true"></ReviewsHost>
                              </div>
                            </div>
                          </div>

                          <!-- Guest Reviews Tab -->
                          <div v-if="!loading" class="tab-pane fade scroll-width-thin" id="guestReviews">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                <ReviewsGuest :reviewsFeed="guestReviews" :hideRating="true" :showAdditionalInfo="true"></ReviewsGuest>
                              </div>
                            </div>
                          </div>

                          <!-- Service Reviews Tab -->
                          <div v-if="!loading" class="tab-pane fade scroll-width-thin" id="serviceReviews">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                <ReviewsServices :reviewsFeed="serviceReviews" :hideRating="true" :showAdditionalInfo="true"></ReviewsServices>
                              </div>
                            </div>
                          </div>

                          <!-- Neighbourhood Reviews Tab -->
                          <div v-if="!loading" class="tab-pane fade scroll-width-thin" id="neighbourhoodReviews">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                <ReviewsNeighbourhood :reviewsFeed="neighbourhoodReviews" :hideRating="true"></ReviewsNeighbourhood>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loader from "../Loader";
import ValidationErrors from "../ValidationErrors";
import ReviewsHost from "../Reviews/ReviewsHost";
import ReviewsGuest from "../Reviews/ReviewsGuest";
import ReviewsServices from "../Reviews/ReviewsServices";
import ReviewsNeighbourhood from "../Reviews/ReviewsNeighbourhood";

export default {
  name:"DashboardReviews",
  components: {
    'ValidationErrors': ValidationErrors,
    'Loader': Loader,
    'ReviewsHost': ReviewsHost,
    'ReviewsGuest': ReviewsGuest,
    'ReviewsServices': ReviewsServices,
    'ReviewsNeighbourhood': ReviewsNeighbourhood
  },
  data(){
    return {
      user: (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) ? this.$store.state.auth.user : null,
      loading: true,
      processing: false,
      hostReviews: [],
      guestReviews: [],
      serviceReviews: [],
      neighbourhoodReviews: [],
      validationErrors: '',
      validationMessage: '',
      validationSuccess: '',
      showError: false
    }
  },
  async mounted() {
    this.processing = true;
    this.loading = true;
    if (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) {
      // Host Reviews
      await this.getUserReviews('host');
      // Guest Reviews
      await this.getUserReviews('guest');
      // Service Reviews
      await this.getUserReviews('service');
      // Neighbourhood Reviews
      await this.getUserReviews('neighbourhood');
    }
    this.loading = false;
    this.processing = false;
  },
  computed:{

  },
  async created() {

  },
  beforeDestroy() {

  },
  methods: {
    openAdDetails(id) {
      // Open propDetails (AdDetails.vue) to show specific favourite bookmark
      this.$root.$emit('openPropDetailsGlobal', id);
    },
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    async getUserReviews(type = null) {
      this.processing = true;
      this.loading = true;

      let formData = new FormData();

      formData.append('user_id', this.$store.state.auth.user.id);
      if (type) {
        formData.append('review_type', type);
      } else {
        formData.append('review_type', 'all');
      }

      // null is our paginate
      await axios.post('api/reviews/user/' + this.$store.state.auth.user.id + '/', formData).then(async ({data}) => {
        if (data['hostReviews']) {
          this.hostReviews = data['hostReviews'];
        }
        if (data['guestReviews']) {
          this.guestReviews = data['guestReviews'];
        }
        if (data['serviceReviews']) {
          this.serviceReviews = data['serviceReviews'];
        }
        if (data['neighbourhoodReviews']) {
          this.neighbourhoodReviews = data['neighbourhoodReviews'];
        }
        this.showError = false;
        this.validationSuccess = null;
        this.validationMessage = '';
        this.validationErrors = '';
        this.hostCompletedBookings = data.data;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.loading = false;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
        }
      }).finally(() => {
        this.loading = false;
        this.processing = false;
      })
    },
  }
}
</script>


<style scoped lang="scss">

#hostReviews {
  color: black;
}
#guestReviews {
  color: black;
}
#serviceReviews {
   color: black;
}
#neighbourhoodReviews {
  color: black;
}


/* start tooltip - popups info on buttons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  margin-left: 10px;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on buttons */

// Tabs
.dashboard-reviews {
  .nav-item .nav-link {
    color: #818080;
    &:hover {
      color: #3d3d3d;
    }
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #3d3d3d;
    background: transparent;
    position: relative;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active:first-child:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: inherit;
    background: linear-gradient(to left, rgba(128, 0, 128, 0.3), rgba(25, 181, 254, 0.3));
    top: 0;
    left: 0;
  }
}
// End Tabs

.dashboard-header {
  letter-spacing: 2px;
}

#reviewsList {
  height: auto;
  min-height: 400px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 767px) {

}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media (max-height: 800px) and (min-width: 1200px) {

}
@media screen and (min-width: 1680px) {

}
@media (min-height: 1200px) and (min-width: 1600px) {

}
@media screen and (min-width: 1920px) {

}

</style>